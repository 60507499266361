import Merge from 'lodash/merge'
import MergeWith from 'lodash/mergeWith'
import Omit from 'lodash/omit'
import { isLoggedIn } from '@utils/auth'
import {
  getPreferenceApi,
  updatePreferenceApi,
  getTenantPreferenceApi,
  updateTenantPreferenceApi,
  // updateTenantPreferencesApi,
} from './api'

const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
export const state = {
  preference: {
    loading: false,
    modulePreferences: {},
    tenantPreferences: {},
    myAllowedModules: [],
    supportChat: false,
    supportContactOps: false,
    pageSize: undefined,
    theme: undefined,
    attachmentPassword: '',
  },
  appliedTheme: getSavedState('applied.theme'),
}

export const getters = {
  loading(state) {
    return state.preference.loading
  },
  preferences(state) {
    return state.preference
  },
  myAllowedModules(state) {
    return state.preference.myAllowedModules
  },
  defaultLayout(state) {
    return (moduleName) => {
      return (
        (state.preference.modulePreferences[moduleName] || {}).defaultLayout ||
        'list'
      )
    }
  },
  favouriteItems(state) {
    return (moduleName) => {
      return (
        (state.preference.modulePreferences[moduleName] || {}).favouriteItems ||
        []
      )
    }
  },
  pageSize(state) {
    return (moduleName) => {
      if (!moduleName) {
        return state.preference.pageSize
      }
      return (
        (state.preference.modulePreferences[moduleName] || {}).pageSize ||
        state.preference.pageSize
      )
    }
  },
  defaultSearchId(state) {
    return (moduleName) => {
      return (state.preference.modulePreferences[moduleName] || {})
        .defaultSearchId
    }
  },
  defaultListingColumns(state) {
    return (moduleName) => {
      return (
        (state.preference.modulePreferences[moduleName] || {}).columns || []
      )
    }
  },
  defaultListingColumnsForSupportPortal(state) {
    return (moduleName) => {
      return (
        (state.preference.modulePreferences[moduleName] || {})
          .columnsForSupportPortal || []
      )
    }
  },
  defaultKanbanStatusColumns(state) {
    return (moduleName) => {
      return (
        (state.preference.modulePreferences[moduleName] || {})
          .kanbanStatusColumns || []
      )
    }
  },
  tenantPrefrences(state) {
    return state.preference.tenantPreferences
  },
  getSingleTenantPrefrence(state) {
    return (key) => {
      if (state.preference.tenantPreferences[key]) {
        return state.preference.tenantPreferences[key].value
      }
      return null
    }
  },
  maxAllowedFileSize(state) {
    return (state.preference.tenantPreferences.maxFileSize || {}).value
  },
  thresholdLeaveBalance(state) {
    return (state.preference.tenantPreferences.thresholdLeaveBalance || {})
      .value
  },
  // maxAllowedFilesToUpload(state) {
  //   return (state.preference.tenantPreferences.maxMultiFileUpload || {}).value
  // },
  shortcutEnabled(state) {
    return state.preference.shortcutEnabled
  },
  webNotificationPrefs(state) {
    return state.preference.webNotificationPrefs
  },
  showUiNotification(state) {
    return state.preference.showUiNotification
  },
  chatEnabled(state) {
    return state.preference.supportChat
  },
  contactOpsEnabled(state) {
    return state.preference.supportContactOps
  },
  rawTheme(state) {
    return state.preference.theme
  },
  // theme(state) {
  //   const theme = state.preference.theme
  //   if (theme === 'auto') {
  //     return state.appliedTheme
  //   }
  //   return state.preference.theme
  // },
  theme(state) {
    return state.preference.theme
  },
  isSetupCompleted(state) {
    return state.preference.isSetupCompleted
  },
  dashboardPreferenceForUser(state) {
    return state.preference.dashboardPreferenceForUser
  },
  allowedFileExtensions(state) {
    return (state.preference.tenantPreferences.blockFileAttachment || {}).value
  },
  attachmentPassword(state) {
    return state.preference.attachmentPassword
  },
  allowConversationSaveInDraft(state) {
    return (
      state.preference.tenantPreferences.saveRepliesConversationInDraft || {}
    ).value
  },
  allowUserToSelectFromEmail(state) {
    return (state.preference.tenantPreferences.allowUserToSelectFromEmail || {})
      .value
  },
  allowUserToReopenClosedTasks(state) {
    return (
      state.preference.tenantPreferences.allowUserToReopenClosedTasks || {}
    ).value
  },
  allowUserToDeleteTasks(state) {
    return (state.preference.tenantPreferences.allowUserToDeleteTasks || {})
      .value
  },
}

export const mutations = {
  SET_PREFERENCES(state, payload) {
    state.preference.loading = payload.loading || false
    state.preference = {
      ...state.preference,
      ...payload,
    }
  },
  SET_TENANT_PREFERENCES(state, payload) {
    state.preference.loading = payload.loading || false
    state.preference = {
      ...state.preference,
      tenantPreferences: {
        ...state.preference.tenantPreferences,
        ...payload,
      },
    }
  },
  SET_APPLIED_THEME(state, theme) {
    saveState('applied.theme', theme)
    state.appliedTheme = theme
  },
}

export const actions = {
  init({ dispatch, commit }) {
    const listener = (e) => {
      if (state.preference.theme === 'auto') {
        commit('SET_APPLIED_THEME', e.matches ? 'black' : 'white')
      }
    }
    mediaQuery.removeEventListener('change', listener)
    mediaQuery.addEventListener('change', listener)
    if (state.preference.theme === 'auto') {
      commit('SET_APPLIED_THEME', mediaQuery.matches ? 'black' : 'white')
    }
    if (isLoggedIn()) {
      dispatch('fetch')
      dispatch('fetchTenantPrefrence')
    }
  },

  fetch({ commit }) {
    return getPreferenceApi().then((data) => {
      commit('SET_PREFERENCES', Object.freeze(data))
      return data
    })
  },

  fetchTenantPrefrence({ commit }, params) {
    return getTenantPreferenceApi(params).then((data) => {
      commit('SET_TENANT_PREFERENCES', Object.freeze(data))
    })
  },

  updatePreference({ commit, state }, payload) {
    const mergedData = MergeWith(
      {},
      state.preference,
      payload,
      (objValue, srcValue) => {
        if (Array.isArray(objValue)) {
          return srcValue
        }
      }
    )
    return updatePreferenceApi(mergedData).then((data) => {
      commit('SET_PREFERENCES', Object.freeze(data))
    })
  },
  updateTenantPreference({ commit, state }, payload) {
    const mergedData = Merge({}, state.preference.tenantPreferences, payload)
    return updateTenantPreferenceApi(mergedData).then((data) => {
      commit('SET_TENANT_PREFERENCES', Object.freeze(data))
    })
  },

  updateModulePreference({ dispatch }, payload) {
    if (!payload.moduleName) {
      throw new Error('Module name is required in update module preference')
    }
    return dispatch('updatePreference', {
      modulePreferences: {
        [payload.moduleName]: Omit(payload, ['moduleName']),
      },
    })
  },

  /**
   * destroy all states when user is logged out
   */
  destroy({ commit }) {
    commit('SET_PREFERENCES', {
      modulePreferences: {},
      loading: true,
    })
  },
}
function getSavedState(key) {
  return (
    JSON.parse(window.localStorage.getItem(key)) || {
      appliedTheme: mediaQuery.matches ? 'black' : 'white',
      theme: 'auto',
      columns: [],
    }
  )
}
function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state))
}
