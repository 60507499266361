import Constants from '@constants'
import { isLoggedIn } from '@utils/auth'
import { getLocationsApi } from './api'
import { getHierarchyConfigApi } from '@components/hierarchy-config/hierarchy-api'
export const state = {
  locations: [],
}

export const getters = {
  locations(state) {
    return state.locations
  },
  locationOptions(state) {
    return state.locations
  },
}

export const mutations = {
  SET_LOCATIONS(state, data) {
    state.locations = data
  },
}

export const actions = {
  init({ dispatch }) {
    if (isLoggedIn()) {
      dispatch('fetch')
    }
  },

  fetch({ commit }, params) {
    return getLocationsApi(false, params).then((data) => {
      commit('SET_LOCATIONS', data)
      return data
    })
  },

  /**
   * destroy all states when user is logged out
   */
  destroy({ commit }) {
    commit('SET_LOCATIONS', [])
  },
}

function getLocationConfig() {
  const vuexModule = {
    namespaced: true,
  }
  vuexModule.state = {
    locationConfig: {},
  }

  vuexModule.getters = {
    locationConfig(state) {
      return state.locationConfig
    },
    reportHierarchy(state) {
      return state.locationConfig.reportHierarchy
    },
  }

  vuexModule.mutations = {
    SET_HIERARCHY_CONFIG(state, data) {
      state.locationConfig = data
    },
  }

  vuexModule.actions = {
    init({ dispatch }) {
      if (isLoggedIn()) {
        dispatch('fetchConfig')
      }
    },

    fetchConfig({ commit, rootGetters }, params) {
      getHierarchyConfigApi(Constants.LOCATION).then((data) => {
        commit('SET_HIERARCHY_CONFIG', Object.freeze(data))
      })
    },

    /**
     * destroy all states when user is logged out
     */
    destroy({ commit }) {
      commit('SET_HIERARCHY_CONFIG', {})
    },
  }
  return vuexModule
}

export const modules = {
  locationConfig: getLocationConfig(),
}
